import {useState, useRef} from 'react'
import {Link} from 'react-router-dom'
import LandingIntro from './LandingIntro'
import ErrorText from  '../../components/Typography/ErrorText'
import SuccessText from  '../../components/Typography/SuccessText'
import InputText from '../../components/Input/InputText'
import axios from 'axios'
import PasswordChecklist from 'react-password-checklist'
import DOMPurify from 'isomorphic-dompurify';

function ResetPassword(){

    const INITIAL_RESET_OBJ = {
        password : "",
        passwordRepeat : "",
    }
    const queryParameters = new URLSearchParams(window.location.search)
    const token = queryParameters.get("token");

    const [loading, setLoading] = useState(false)
    const [errorMessage, setErrorMessage] = useState("")
    const [resetObj, setResetObj] = useState(INITIAL_RESET_OBJ)
    const [successMessage, setSuccessMessage] = useState("");

    

    const submitForm = async (e) =>{
        e.preventDefault()
        setErrorMessage("")
        setSuccessMessage("")
        if(resetObj.password.trim() === "") return setErrorMessage("¡La contraseña es necesaria!")
        if(resetObj.passwordRepeat.trim() === "") return setErrorMessage("¡La repetición de la contraseña es necesaria!")
        if(resetObj.passwordRepeat.trim() !== resetObj.password.trim()) return setErrorMessage("¡Las contraseñas no coinciden!")
        else{
            setLoading(true)
            // Call API to check user credentials and save token in localstorage
            await axios.post("api/auth/reset-password", {
                token: token,
                password: resetObj.password.trim(),
                passwordRepeat: resetObj.passwordRepeat.trim()
            })
            .then((response) => {
                setSuccessMessage(response.data.message);
                setLoading(false)
                setTimeout(function() {
                    window.location.replace('/login');
                }, 5000);
            })
            .catch((error) => {
                setLoading(false)
                if(error.response.data.message){
                    return setErrorMessage(error.response.data.message);
                }
                setErrorMessage("Ha ocurrido un error inesperado.")
            });
        }
    }

    const updateFormValue = ({updateType, value}) => {
        setErrorMessage("")
        setResetObj({...resetObj, [updateType] : value})
    }
    if(!token){
        window.location.replace('/login');
    }
    return(
        <div className="min-h-screen bg-base-200 flex items-center">
            <div className="card mx-auto w-full max-w-5xl  shadow-xl">
                <div className="grid  md:grid-cols-2 grid-cols-1  bg-base-100 rounded-xl">
                <div className=''>
                        <LandingIntro />
                </div>
                <div className='py-24 px-10'>
                    <h2 className='text-2xl font-semibold mb-2 text-center'>Restaurar Contraseña</h2>
                    <form onSubmit={(e) => submitForm(e)}>

                        <div className="mb-4">

                            <InputText defaultValue={resetObj.password} type="password" updateType="password" containerStyle="mt-4" labelTitle="Contraseña" updateFormValue={updateFormValue} />

                            <InputText defaultValue={resetObj.passwordRepeat} type="password" updateType="passwordRepeat" containerStyle="mt-4" labelTitle="Repite la contraseña" updateFormValue={updateFormValue}/>

                            
                        </div>
                        <PasswordChecklist
                                rules={["minLength","specialChar","number","capital","match"]}
                                minLength={8}
                                value={resetObj.password}
                                valueAgain={resetObj.passwordRepeat}
                                messages={{
                                    minLength: "La contraseña tiene más de 8 caracteres.",
                                    specialChar: "La contraseña tiene caracteres especiales.",
                                    number: "La contraseña tiene un número.",
                                    capital: "La contraseña tiene una letra mayúscula.",
                                    match: "Las contraseñas coinciden.",
                                }}
                            />
                        <ErrorText styleClass="mt-8">{errorMessage}</ErrorText>
                        <SuccessText styleClass="mt-8">{successMessage}</SuccessText>
                        <button type="submit" className={"btn mt-2 w-full btn-primary" + (loading ? " loading" : "")}>Restaurar</button>
                         
                        <div className='text-center mt-4'>¿Ya tienes una cuenta? <Link to="/login"><span className="  inline-block  hover:text-primary hover:underline hover:cursor-pointer transition duration-200">Login</span></Link></div>
                        <div className='text-center mt-4'>¿Necesitas pedir un nuevo cambio de contraseña? <Link to="/forgot-password"><span className="  inline-block  hover:text-primary hover:underline hover:cursor-pointer transition duration-200">Contraseña Olvidada</span></Link></div>
                    </form>
                </div>
            </div>
            </div>
        </div>
    )
}

export default ResetPassword