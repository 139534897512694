import React, { lazy, useEffect } from 'react'
import './App.css';
import { BrowserRouter as Router, Route, Routes, Navigate, useNavigate,useLocation } from 'react-router-dom'
import { themeChange } from 'theme-change'
import checkAuth from './app/auth';
import validToken from './app/validToken';
import initializeApp from './app/init';
import ResetPassword from './features/user/ResetPassword';

// Importing pages
const Layout = lazy(() => import('./containers/Layout'))
const Login = lazy(() => import('./pages/Login'))
const ForgotPassword = lazy(() => import('./pages/ForgotPassword'))
const EmailValidate = lazy(() => import('./pages/EmailValidate'))
const Register = lazy(() => import('./pages/Register'))


initializeApp()


const token = checkAuth()

function InnerComponent() {
  const location = useLocation();
  useEffect(() => {
    const handleNavigationChange = async () => {
      await validToken(localStorage.getItem("token"));
    };

    handleNavigationChange();
  }, [location]);

  return null;
}

function App() {
  useEffect(() => {
    themeChange(false)
  }, [])


  return (
    <>
      <Router>
        <InnerComponent />
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/email-validate" element={<EmailValidate />} />
          <Route path="/register" element={<Register />} />

          {/* Place new routes over this */}
          <Route path="/app/*" element={<Layout />} />

          <Route path="*" element={<Navigate to={token ? "/app/welcome" : "/login"} replace />}/>

        </Routes>
      </Router>
    </>
  )
}

export default App
