import axios from "axios"

const validateToken = async (TOKEN) => {

    if(TOKEN){
      await axios.post('/api/auth/validate_token',{token:TOKEN})
      .then((response) => {
        localStorage.setItem("role", response.data.role);
      })
      .catch((error) => {
          // handle errors
          console.log(error);
          localStorage.removeItem("token")
          window.location.href = '/login';
      });
        
    }
}

export default validateToken